import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        We are proud to serve Truly Great Coffee & Freshly Made Food!
      </Typography>
      <Typography variant="body1" paragraph>
        Our independent artisan local coffee shop 'Westmount Cafe' brings
        handcrafted bread, pastries, and freshly made food to Eltham. We created
        a relaxed, calm atmosphere for all coffee lovers. Coffee is our core
        value - starting with the bean, the brew, the way we present it, and the
        enthusiasm we show when we talk about it makes us stand out.
      </Typography>
      <Typography variant="body1" paragraph>
        Once you've made your order you know your coffee will be fresh every
        time. Our Baristas treat each cup like a fine chef does a meal, taking
        pride and time to ensure each coffee is flawless. We continuously adjust
        the grind to allow for changes in the atmosphere, which is just one of
        the many steps we take to make sure your espresso is just right. We
        clean vital parts of our lovely Wega Concept 3 Coffee Machine all day
        long.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
