const getPalette = (theme) => {
  return {
    type: "light",
    primary: { main: "#8E0000", hover: "#DB0000" },
    secondary: { main: "#8E0000", hover: "#DB0000" },
    accent: "#8E0000",
    info: {
      main: "#8E0000",
    },
    error: {
      main: "#FF0040",
    },
    white: {
      main: "#FFF",
    },
  };
};

export default getPalette;
